import React, {useEffect, useState} from 'react';
// @mui
import {Helmet} from 'react-helmet-async';
import {Card, Container, Grid, Stack, TextField, Typography,} from '@mui/material';
import {Phonelink} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";
import useApiHandlerStore from "../../zustand/useApiHandlerStore";
import useMessagesSnackbar from "../../hooks/messages/useMessagesSnackbar";
import PROJECT_CONFIG from "../../config/config";



// ----------------------------------------------------------------------
const NAME_PAGE = 'Link Device';

const LINK_DEVICE_URL = PROJECT_CONFIG.API_CONFIG.USERS.ACTIVATE;
export default function ActivateDevicePage() {
    const showSnackbarMessage = useMessagesSnackbar();
    const {api} = useApiHandlerStore((state) => state);
    const [formData, setFormData] = useState({
        code: '',
    });

    const [loading, setLoading] = useState(false);

    const handleChange = (event) => {
        const {name, value} = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const response = await api.__post(
          LINK_DEVICE_URL,
          formData,
          (msg) => { showSnackbarMessage(msg, 'error') },
            ( isLoading ) => { setLoading(isLoading) }
        );

        if (response) {
            if (response.success) {
                const msg = `The device has been linked!`;
                showSnackbarMessage(msg, 'success');
                setFormData({
                    code: '',
                });
            } else {
                setFormData({
                    code: '',
                });
                showSnackbarMessage(response.message, 'error');
            }
        }
    };

    useEffect(() => {

    }, []);

    return (
        <>
            <Helmet>
                <title> { 'Link Device'} | { PROJECT_CONFIG.NAME } </title>
            </Helmet>

            <Container>

                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        {NAME_PAGE}
                    </Typography>
                </Stack>
                <Card>
                    <Stack spacing={3} justifyContent="space-between" sx={{m: 2}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4} lg={4}>
                                <Stack spacing={3} justifyContent="space-between" sx={{m: 2}}>
                                    <Typography variant="h6" gutterBottom>
                                        Use the code generated by Player App to finish login.
                                    </Typography>
                                    <TextField
                                        name="code"
                                        value={formData.code ?? ''}
                                        onChange={handleChange}
                                        label="Activate Code"
                                    />
                                    <LoadingButton
                                        color="secondary"
                                        onClick={handleSubmit}
                                        loading={loading}
                                        loadingPosition="start"
                                        startIcon={<Phonelink />}
                                        variant="contained"
                                    >
                                        <span>Link Device</span>
                                    </LoadingButton>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={8} lg={8}>
                                <img src="/assets/illustrations/linkdevice.jpg" alt="login" />
                            </Grid>
                        </Grid>
                    </Stack>

                </Card>
            </Container>
        </>
    );
}
