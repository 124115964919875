import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import {useEffect, useState} from "react";
import {
    Button,
    Card,
    Checkbox, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Popover,
    Stack,
    TablePagination
} from "@mui/material";
import {Delete} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";
import Iconify from "../../components/iconify";
import PROJECT_CONFIG from "../../config/config";
import useApiHandlerStore from "../../zustand/useApiHandlerStore";
import useMessagesAlert from "../../hooks/messages/useMessagesAlert";
import useMessagesSnackbar from "../../hooks/messages/useMessagesSnackbar";
import {formatDate} from "../../utils/formatTime";
import useNavigateTo from "../../hooks/navigateTo";
import {UserListHead, UserListToolbar} from "../../sections/@dashboard/user";
import Scrollbar from "../../components/scrollbar/Scrollbar";
import {applySortFilter, getComparator} from "../../utils/table/tableFunctions";
import palette from '../../theme/palette';
import TableSkeleton from '../../components/table-skeleton';


const URL_GET_DATA = PROJECT_CONFIG.API_CONFIG.IMAGE.ALL;
const URL_DELETE_DATA = PROJECT_CONFIG.API_CONFIG.IMAGE.DELETE;
const URL_EDIT_IMAGE = '/dashboard/image/edit/';
const URL_EDIT_VIDEO = '/dashboard/video/edit/';

const TABLE_HEAD = [
    {id: 'name', label: 'Name', alignRight: false},
    {id: 'duration', label: 'Duration', alignRight: false},
    {id: 'qr', label: 'QR', alignRight: false},
    {id: 'created_at', label: 'Create At', alignRight: false},
    {id: 'updated_at', label: 'Update At', alignRight: false},
    {id: 'actions', label: 'Actions'},
];

// eslint-disable-next-line react/prop-types
export default function MediaDataTable({screen}) {
    const {navigateTo} = useNavigateTo();
    const [open, setOpen] = useState(false);
    const {api} = useApiHandlerStore((state) => state);
    const showMessageAlert = useMessagesAlert();
    const showMessageSnackbar = useMessagesSnackbar();
    const [dataTable, setDataTable] = useState([]);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('created_at');
    const [filterQuery, setFilterQuery] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(PROJECT_CONFIG.TABLE_CONFIG.ROW_PER_PAGE);
    const [loading, setLoading] = useState(false);
    const [showSkeleton, setShowSkeleton] = useState(false);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [rowsForDelete, setRowsForDelete] = useState([]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = dataTable.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByQuery = (event) => {
        setPage(0);
        setFilterQuery(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataTable.length) : 0;

    const filteredDataTable = applySortFilter({
        array: dataTable,
        comparator: getComparator({_order: order, _orderBy: orderBy}),
        query: filterQuery
    });

    const isNotFound = !filteredDataTable.length && !!filterQuery;

    const handleOpenMenu = (event) => {
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleDeleteSelected = () => {
        setRowsForDelete(selected)
        setOpenConfirmDelete(true)
    }

    const handleEditSelected = () => {
        if (selected.length === 1) {
            editAction(selected[0])
        }
    }

    const deleteRows = async () => {
        const data = {'ids': rowsForDelete, 'screen_id': screen};
        const response = await api.__delete(
          URL_DELETE_DATA,
          data,
          (msg) => {showMessageSnackbar(msg, 'error')},
          (_loading) => {setLoading(_loading)},

        )

        if (response) {
            showMessageAlert(response.message, 'success');
            getData()
            setSelected([]);
        }
        setOpenConfirmDelete(false)
    }

    const editAction = async (item) => {
        handleCloseMenu()
        if (item.dataset.type === "image") {
            navigateTo(`${URL_EDIT_IMAGE}${screen}/${item.id}`);
        } else {
            navigateTo(`${URL_EDIT_VIDEO}${screen}/${item.id}`);
        }
    }

    const handleDeleteItemClick = (item) => {
        handleCloseMenu()
        setRowsForDelete([item.id])
        setOpenConfirmDelete(true)
    }

    const handleCloseConfirmDelete = ()=> {
        setOpenConfirmDelete(false)
        setRowsForDelete([])
    }

    const handleEditItemClick = (item) => {
        handleCloseMenu()
        editAction(item)
    }

    const getData = async () => {
        setShowSkeleton(true);
        const response = await api.__get(
          `${URL_GET_DATA}?screen_id=${screen}`,
          (msg) => {showMessageSnackbar(msg, 'error')}
        )
        setShowSkeleton(false);
        if (response !== undefined && response.data) {
            setDataTable(Object.values(response.data))
        }
    };

    useEffect(() => {
        getData();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Card>
                {
                    showSkeleton ? (
                      <TableSkeleton/>
                    ) : (
                      <>
                          <UserListToolbar
                            numSelected={selected.length}
                            filterQuery={filterQuery}
                            onFilterQuery={handleFilterByQuery}
                            onDeleteSelect={handleDeleteSelected}
                            onEditSelect={handleEditSelected}
                            onlyEdit
                          />

                          <Scrollbar>
                              <TableContainer sx={{minWidth: 800}}>
                                  <Table>
                                      <UserListHead
                                        order={order}
                                        orderBy={orderBy}
                                        headLabel={TABLE_HEAD}
                                        rowCount={filteredDataTable.length}
                                        numSelected={selected.length}
                                        onRequestSort={handleRequestSort}
                                        onSelectAllClick={handleSelectAllClick}
                                      />
                                      <TableBody>
                                          {filteredDataTable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                              const {id, name, duration} = row;
                                              const selectedRow = selected.indexOf(id) !== -1;
                                              const isVideo = row.image === "";

                                              return (
                                                <TableRow hover key={id} tabIndex={-1} role="checkbox"
                                                          selected={selectedRow}>
                                                    <TableCell padding="checkbox">
                                                        <Checkbox checked={selectedRow}
                                                                  onChange={(event) => handleClick(event, id)}/>
                                                    </TableCell>
                                                    <TableCell component="th" scope="row" padding="none">
                                                        <Stack direction="row" alignItems="center" spacing={2}>
                                                            {
                                                                isVideo ? (
                                                                  <Card sx={{p:2}}>
                                                                      <Iconify sx={{color: palette.secondary.darker}} width={45} icon={'mdi:video-vintage'}/>
                                                                  </Card>

                                                                ) : (
                                                                  <img src={row.image} alt={row.description} width="70px" />
                                                                )
                                                            }

                                                            <Typography variant="subtitle2" noWrap>
                                                                {name}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>
                                                    <TableCell align="center">{duration}s</TableCell>
                                                    <TableCell align="center">
                                                        { row.qr_info && (
                                                          <Iconify width="25px" icon="material-symbols:qr-code-2"/>
                                                        ) }
                                                    </TableCell>
                                                    <TableCell align="center">{formatDate(row.created_at)}</TableCell>
                                                    <TableCell align="center">{formatDate(row.updated_at)}</TableCell>

                                                    <TableCell align="center">
                                                        <IconButton id={id} data-type={isVideo ? "video" : "image"} size="large" color="inherit"
                                                                    onClick={handleOpenMenu}>
                                                            <Iconify icon={'eva:more-vertical-fill'}/>
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                              );
                                          })}
                                          {emptyRows > 0 && (
                                            <TableRow style={{height: 53 * emptyRows}}>
                                                <TableCell colSpan={6}/>
                                            </TableRow>
                                          )}
                                      </TableBody>

                                      {isNotFound && (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="center" colSpan={6} sx={{py: 3}}>
                                                    <Paper
                                                      sx={{
                                                          textAlign: 'center',
                                                      }}
                                                    >
                                                        <Typography variant="h6" paragraph>
                                                            Not found
                                                        </Typography>

                                                        <Typography variant="body2">
                                                            No results found for &nbsp;
                                                            <strong>&quot;{filterQuery}&quot;</strong>.
                                                            <br/> Try checking for typos or using complete words.
                                                        </Typography>
                                                    </Paper>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                      )}
                                  </Table>
                              </TableContainer>
                          </Scrollbar>

                          <TablePagination
                            rowsPerPageOptions={PROJECT_CONFIG.TABLE_CONFIG.ROWS_PER_PAGE_OPTIONS}
                            component="div"
                            count={filteredDataTable.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                          />
                      </>
                    )
                }
            </Card>

            <Dialog open={openConfirmDelete} onClose={handleCloseConfirmDelete}>
                <DialogTitle>
                    Delete
                </DialogTitle>
                <DialogContent>
                    <Typography variant="subtitle1" gutterBottom>
                        Are you sure you want to delete the selected data?
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirmDelete}>Cancel</Button>
                    <LoadingButton
                        color="error"
                        onClick={deleteRows}
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<Delete />}
                        variant="contained"
                    >
                        <span>OK</span>
                    </LoadingButton>
                </DialogActions>
            </Dialog>

            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{vertical: 'top', horizontal: 'left'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem onClick={() => handleEditItemClick(open)}>
                    <Iconify icon={'eva:edit-fill'} sx={{mr: 2}}/>
                    Edit
                </MenuItem>

                <MenuItem onClick={() => handleDeleteItemClick(open)} sx={{color: 'error.main'}}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{mr: 2}}/>
                    Delete
                </MenuItem>
            </Popover>
        </>
    )
}